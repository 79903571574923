import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from '../hooks/useApi';
import { useSnackbar } from 'notistack';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Typography,
  CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Users() {
  const { hasPermission, currentUser } = useAuth();
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    pageSize: 20,
    username: '',
    name: '',
    email: ''
  });
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  console.log('Current user:', currentUser);
  console.log('Has view_users permission:', hasPermission('view_users'));

  const { data: usersData, isLoading: usersLoading, error: usersError } = useQuery(
    ['users', searchParams],
    async () => {
      console.log('Fetching users with params:', searchParams);
      try {
        const response = await apiCall(`/api/users/search?${new URLSearchParams(searchParams)}`);
        console.log('Raw API response:', response);
        return response;
      } catch (error) {
        console.error('Error in API call:', error);
        throw error;
      }
    },
    {
      enabled: hasPermission('view_users'),
      onSuccess: (data) => {
        console.log('Users data received:', data);
        if (!data || !Array.isArray(data.data)) {
          console.error('Unexpected data structure:', data);
        }
      },
      onError: (error) => {
        console.error('Error fetching users:', error);
        enqueueSnackbar(`Error fetching users: ${error.message}`, { variant: 'error' });
      }
    }
  );

  const { data: rolesData, error: rolesError } = useQuery(
    'roles',
    () => apiCall('/api/roles'),
    {
      enabled: hasPermission('view_roles'),
      onSuccess: (data) => {
        console.log('Roles data received:', data);
      },
      onError: (error) => {
        console.error('Error fetching roles:', error);
        enqueueSnackbar(`Error fetching roles: ${error.message}`, { variant: 'error' });
      }
    }
  );

  const users = usersData?.data || [];
  const roles = rolesData?.data || [];

  console.log('Processed users:', users);
  console.log('Processed roles:', roles);

  useEffect(() => {
    if (usersError) console.error('Error fetching users:', usersError);
    if (rolesError) console.error('Error fetching roles:', rolesError);
  }, [usersError, rolesError]);

  const createUserMutation = useMutation(
    (newUser) => apiCall('/api/users', 'POST', newUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User created successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        console.error('Error creating user:', error);
        enqueueSnackbar(`Error creating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const updateUserMutation = useMutation(
    (updatedUser) => apiCall(`/api/users/${updatedUser.id}`, 'PUT', updatedUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User updated successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        console.error('Error updating user:', error);
        enqueueSnackbar(`Error updating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const deleteUserMutation = useMutation(
    (userId) => apiCall(`/api/users/${userId}`, 'DELETE'),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
      },
      onError: (error) => {
        console.error('Error deleting user:', error);
        enqueueSnackbar(`Error deleting user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const handleUserDialogOpen = (user = null) => {
    console.log('Opening user dialog for user:', user);
    setSelectedUser(user);
    setUserDialogOpen(true);
  };

  const handlePageChange = (newPage) => {
    console.log('Page changed to:', newPage + 1);
    setSearchParams(prev => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize) => {
    console.log('Page size changed to:', newPageSize);
    setSearchParams(prev => ({ ...prev, pageSize: newPageSize, page: 1 }));
  };

  const handleUserSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userData = Object.fromEntries(formData.entries());
    userData.roles = userData.roles.split(',');

    console.log('Submitting user data:', userData);

    if (selectedUser) {
      updateUserMutation.mutate({ ...selectedUser, ...userData });
    } else {
      createUserMutation.mutate(userData);
    }
  };

  const userColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value && params.value.map((role) => (
            <Chip key={role} label={role} size="small" style={{ margin: '0 2px' }} />
          ))}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          {hasPermission('update_user') && (
            <Button
              startIcon={<EditIcon />}
              onClick={() => handleUserDialogOpen(params.row)}
              size="small"
            >
              Edit
            </Button>
          )}
          {hasPermission('delete_user') && (
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => deleteUserMutation.mutate(params.row.id)}
              size="small"
              color="error"
            >
              Delete
            </Button>
          )}
        </Box>
      ),
    },
  ];

  if (!currentUser) {
    console.log('No current user, showing loading');
    return <CircularProgress />;
  }

  if (!hasPermission('view_users')) {
    console.log('User does not have view_users permission');
    return <Typography>You do not have permission to view this page.</Typography>;
  }

  console.log('Rendering Users component');

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        {hasPermission('create_user') && (
          <Button
            startIcon={<AddIcon />}
            onClick={() => handleUserDialogOpen()}
            variant="contained"
            sx={{ mb: 2 }}
          >
            Add User
          </Button>
        )}
        <Paper sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={users}
            columns={userColumns}
            page={searchParams.page - 1}
            pageSize={searchParams.pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowCount={usersData?.pagination?.total || 0}
            checkboxSelection
            disableSelectionOnClick
            loading={usersLoading}
            getRowId={(row) => row.id}
            error={usersError}
          />
        </Paper>
      </Box>

      {/* User Dialog */}
      <Dialog open={userDialogOpen} onClose={() => setUserDialogOpen(false)}>
        <form onSubmit={handleUserSubmit}>
          <DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="username"
              label="Username"
              type="text"
              fullWidth
              defaultValue={selectedUser?.username}
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedUser?.name}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              defaultValue={selectedUser?.email}
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="roles-label">Roles</InputLabel>
              <Select
                labelId="roles-label"
                name="roles"
                multiple
                defaultValue={selectedUser?.roles || []}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUserDialogOpen(false)}>Cancel</Button>
            <Button type="submit">{selectedUser ? 'Update' : 'Create'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Users;
