import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiCall = useCallback(async (endpoint, method = 'GET', body = null, token = null) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const config = {
      method,
      headers,
      credentials: 'include',
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    console.log(`Making API call to ${endpoint}`, { method, headers, body }); // Log API call details

    const response = await fetch(`https://d1-petdb.yoda-md.workers.dev${endpoint}`, config);
    if (!response.ok) {
      const errorData = await response.json();
      console.error(`API call failed: ${endpoint}`, errorData); // Log API call failure
      throw new Error(errorData.error || 'API call failed');
    }
    const data = await response.json();
    console.log(`API call successful: ${endpoint}`, data); // Log API call success
    return data;
  }, []);

  const logout = useCallback(() => {
    console.log('Logging out user'); // Log logout action
    localStorage.removeItem('token');
    setCurrentUser(null);
  }, []);

  const fetchUserData = useCallback(async (token) => {
    try {
      console.log('Fetching user data'); // Log fetch attempt
      // Decode the JWT to get the user ID
      const payload = JSON.parse(atob(token.split('.')[1]));
      const userId = payload.userId;

      // Fetch user data using the correct endpoint
      const userData = await apiCall(`/api/users/${userId}`, 'GET', null, token);

      // Extract permissions from roles
      const permissions = extractPermissionsFromRoles(userData.roles);

      console.log('User data fetched successfully', { ...userData, permissions }); // Log fetched user data
      setCurrentUser({ ...userData, token, permissions });
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [apiCall, logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('Token found in localStorage, fetching user data'); // Log token found
      fetchUserData(token);
    } else {
      console.log('No token found in localStorage'); // Log no token found
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = async (username, password) => {
    try {
      console.log('Attempting login'); // Log login attempt
      const data = await apiCall('/auth', 'POST', { username, password });
      if (data.token) {
        console.log('Login successful, setting token and fetching user data'); // Log successful login
        localStorage.setItem('token', data.token);
        await fetchUserData(data.token);
        return { token: data.token };
      } else {
        console.log('Login failed, no token received'); // Log failed login
        return { error: data.error || 'Login failed' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { error: 'An unexpected error occurred' };
    }
  };

  const hasPermission = (requiredPermissions) => {
    if (!currentUser || !currentUser.permissions) {
      console.log(`Permission check failed:`, { requiredPermissions, currentUser });
      return false;
    }
    
    if (typeof requiredPermissions === 'string') {
      const hasPermission = currentUser.permissions.includes(requiredPermissions);
      console.log(`Permission check: ${requiredPermissions}`, { hasPermission, userPermissions: currentUser.permissions });
      return hasPermission;
    } else if (typeof requiredPermissions === 'object') {
      if (requiredPermissions.type === 'complex') {
        return requiredPermissions.permissions.some(permissionSet => {
          if (Array.isArray(permissionSet)) {
            // OR condition
            return permissionSet.some(permission => 
              currentUser.permissions.includes(permission)
            );
          } else {
            return currentUser.permissions.includes(permissionSet);
          }
        });
      }
    }
    console.log(`Permission check failed: Invalid permission structure`, { requiredPermissions });
    return false;
  };

  // Helper function to extract permissions from roles
  const extractPermissionsFromRoles = (roles) => {
    const rolePermissions = {
      'superadmin': ['read_users', 'create_users', 'update_users', 'delete_users', 'read_roles', 'create_roles', 'update_roles', 'delete_roles'],
      'staff': ['read_users'],
      'volunteer': [],
      'owner': [],
      'citizen': []
    };

    const permissions = roles.reduce((permissions, role) => {
      return [...permissions, ...(rolePermissions[role] || [])];
    }, []);

    console.log('Extracted permissions from roles', { roles, permissions }); // Log extracted permissions
    return permissions;
  };

  const value = {
    currentUser,
    login,
    logout,
    hasPermission,
    apiCall,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
